<script>
import { authMethods } from "@/state/helpers";
import axios from 'axios';
import { parseErrors } from '../../../helpers'

export default {
  data() {
    return {
      email: "",
      password: null,
      tryingToReset: false,
      resetDone: false,
      errors:[],
    };
  },
  computed: {
   
    isFxHedge() {
      return process.env.VUE_APP_HEDGE === 'yes';
    },
    isVH() {
      return process.env.VUE_APP_VH === 'yes';
    },
  },
  props: ['code'],
  created() {
    document.body.classList.add("auth-body-bg");
  },
  methods: {
    ...authMethods,
    // Try to register the user in with the email, fullname
    // and password they provided.
    async tryToReset() {
      this.tryingToReset = true;
        
        this.errors = [];
        const { email, password } = this;
        if (email && password) {
          const URL = `${process.env.VUE_APP_PUBLIC_USR_MNGM_URL}users/fx-hedge/reset-password`;
          try {
            await axios.post(URL, {
              email, password, code: this.code
            });
            this.resetDone = true;
            //this.$router.push('/login');
          } catch(e) {
            console.error(e);
            this.errors= parseErrors(e);
            console.log('errors', this.errors);
          } finally {
            this.tryingToReset = false;
          }
        }
    }
  }
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <img src="@/assets/images/logo_dark_hedge.png" alt="LOGO" class="img-fluid  mt-2 mb-4" style=" height: 60px;" v-if="isFxHedge"/>
                          <img src="@/assets/images/logo-vh.png" alt="LOGO" class="img-fluid  mt-2 mb-4" style=" height: 60px;" v-else-if="isVH"/>
                          <img src="@/assets/images/c8-logo-w.svg" alt="LOGO" class="img-fluid  mt-2 mb-4" style=" height: 50px;" v-else/>
                         <!-- <img
                           style="max-height:60px;"
                            src="@/assets/c8-logo.jpg"
                            class="img-fluid mt-2 mb-4"
                            alt
                          /> -->
                        </div>
                      </div>
                      <b-alert
                          variant="danger"
                          class="mt-3 mb-3"
                          v-if="errors.length > 0"
                          show
                          dismissible
                        >
                        <ul>
                          <li v-for="(e, ei) in errors" :key="ei">{{ e }}</li>
                        </ul>
                      </b-alert>
                      <p class="alert alert-success" v-if="resetDone">
                        Your password has been reset. <a href="/login">Login</a> please.
                      </p>
                      <div class="form-horizontal mt-4" v-else>
                       
                        <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-mail-line auti-custom-input-icon"></i>
                            <label for="email">Your Email</label>
                            <input
                              type="email"
                              v-model="email"
                              class="form-control"
                              id="email"
                              placeholder=""
                            />
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                          <i class="ri-lock-2-line auti-custom-input-icon"></i>
                          <label for="userpassword">{{$t("pages.login.password")}}</label>
                          <input
                            v-model="password"
                            type="password"
                            class="form-control"
                            id="userpassword"
                            placeholder=""
                            autocomplete="new-password"
                          />
                          
                        </div>

                        <div class="d-flex align-items-middle mt-4 justify-content-center">
                          <button
                            class="btn btn-primary w-md waves-effect waves-light"
                            type="button"
                            :disabled="tryingToReset || !email || !password"
                            @click="tryToReset"
                          >
                          <i class="fa fa-spin fa-spinner" v-if="tryingToReset"></i>
                          Set New Password</button>
                        </div>
                      </div>
                      
                     

                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>